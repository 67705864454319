import { Component, BaseComponent, Prop } from '@zento-lib/components';

import { CmsBlock } from '../CmsBlock/CmsBlock';

import type { ICountdown } from './Countdown.d';
import style from './style.scss';

/**
 * Countd
 *
 * Thank you page allowing to show order details.
 **/
@Component({})
export class Countdown extends BaseComponent<ICountdown, any> {
  private static T = {
    days: 'counter_days',
    hours: 'counter_hours',
    minutes: 'counter_minutes',
    seconds: 'counter_seconds',
  };

  /**
   * Widget Countdown Style
   */
  @Prop({ type: Boolean })
  widgetStyle?: boolean;

  data() {
    return {
      feedback: '',
    };
  }

  /**
   * Determines active sections
   */
  updateTimer() {
    if (this.$refs.timer as HTMLFormElement) {
      const future = Date.parse(this.extended.$config.zento.theme.countdown.dateToStart);
      const now = new Date() as any;
      const diff = future - now;

      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const mins = Math.floor(diff / (1000 * 60));
      const secs = Math.floor(diff / 1000);

      const d = days;
      const h = hours - days * 24;
      const m = mins - hours * 60;
      const s = secs - mins * 60;

      const html =
        '<div>' +
        d +
        `<span>${this.getTranslation({ id: Countdown.T.days })}</span></div>` +
        '<div>' +
        h +
        `<span>${this.getTranslation({ id: Countdown.T.hours })}</span></div>` +
        '<div>' +
        m +
        `<span>${this.getTranslation({ id: Countdown.T.minutes })}</span></div>` +
        '<div>' +
        s +
        `<span>${this.getTranslation({ id: Countdown.T.seconds })}</span></div>`;

      (this.$refs.timer as HTMLFormElement).innerHTML = html;
    }
  }

  mounted() {
    setInterval(this.updateTimer() as any, 1000);
  }

  render() {
    setInterval(this.updateTimer as any, 1000);

    return (
      <div
        class={{
          [style.counter]: true,
          [style.topWidgetStyle]: !this.widgetStyle,
          [style.widgetStyle]: this.widgetStyle,
        }}>
        <CmsBlock class={style.counterInfoBlock} identifier='counter-info-block' key='counter-info-block' />

        <div ref='timer' class={style.timer}></div>
      </div>
    );
  }
}
